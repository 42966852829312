.active{
    color: #011F47;
}
.sidebar {
    float: left;
    width: 100px;
    /* position: absolute; */
    /* top: 5em; */
    margin-top: 60px;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #023373 0% 0% no-repeat padding-box;
}
.sidebar .list-group a {
	/* padding: 1.97em 1em !important; */
    text-align: center;
     /* background: #666666; */
     background: #023373 0% 0% no-repeat padding-box;
     color:#fff;
    border-radius: 0;
    /*padding: 8px 21px !important;*/
    width: 100px;
    height: 13vh;
    border: 0;
    padding-top: 15% !important;
}
.sidebar .menu-icon {
	display: block;
	font-size: 2em;
}

.siderbar-menu label {
    font-size: 12px;
    /* line-height: 13px; */
    margin: 0;
    display: block;
    width: 73px;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 3%);
}
.sidebar .list-group .list-group-item.active{
    background: #011F47 0% 0% no-repeat padding-box !important;
}
.sidebar .list-group .list-group-item.active .triangle-left{
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-right: 9px solid white;
    border-bottom: 9px solid transparent;
    float: right;
    position: absolute;
    /* right: -20px; */
    left: 91px;
    /* top: 13px; */
    bottom: 32px;
}
/*.sidebar .siderbar-menu {
    margin-top: 10px;
}*/
.sidebar .sidebar-icon {
    padding-bottom: 3px;
}