.borders{
    border-bottom: 2px solid #ccc;
    border-right: 1px solid #ccc;
    text-align: center !important;
}
.borders-bottom{
    border-bottom: 2px solid #ccc;
}
.borders-right{
    border-right: 1px solid #ccc;
}
th{
    text-align:left !important;
}
.acceptanceHistory{
   font-size: 11px !important;
    text-decoration: underline;
    /* cursor: pointer !important; */
    float: right;
    margin-top: -29px !important;
    /* margin-top: -10px; */
}
.firstAccep{
    font-size: 11px !important;
    text-decoration: underline;
    float: right;
}
/* .singleAcceptance{
    float: right;
    font-size: 11px !important;
    text-decoration: underline;
} */
.historyModelHeading{
    font-size: 20px !important;
    text-align:center !important;
}
.historyHeading{
    /* font-weight: bold; */
}
.CardHeight{
    height:35px;
}
.attchment{
    margin-top:10px;
    font-size:18px;
    
}
.attchmentFile{
    margin-top:-10px;
    text-decoration: underline;
    font-weight: normal !important;
}
.updatedBy-text{
    float:right;
    /* margin-top:4px !important; */
}
.updatedAt-text{
    /* float:right; */
    margin-bottom:4px !important;
}
.modalBodypadding{
    padding-top: 4px !important;
}
.emptyDiv{
    margin-top:8px;
}
.updatedByHeading-text{
    float:right;
    margin-right: 10px;
    
}
.updatedAtHeading-text{
    float:right;
    margin-right:7px;
}
.acceptanceUpdatedAt{
    margin-left: 10px;
}
.acceptanceHistoryscrollbar{
    overflow-y: auto !important;
    width:100% !important;
    max-height: 300px !important;
    /* margin-bottom: 10px; */
}
.blackButton{
    background-color: black;
    color: white;
  }
  
  .whiteButton{
    background-color: white;
    color: black;
  }
  .hideCmt{
    color:transparent;
      
  }
  .file1{
    height: 54px;
    width: 71%;
    display: inline-flex;
    overflow-x: none !important;
}
.fileLayout .drag-text {
    position: absolute;
    top: 69%;
    right: 38%;
    margin-right: 0px;
    -ms-transform: translate(-2px, -33px);
    transform: translate(392px, -33px) !important;
    margin-left: -3%;
}
.select {
    height: 70px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
