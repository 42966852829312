.grid path {
    stroke-width: 0;
  }
  
  .grid .tick line {
    stroke: #9FAAAE;
    stroke-opacity: 0.3;
  }
  .opacityBlack {
    fill-opacity: 1;
  }
  .toolTip {
    position: absolute;
    min-width: 50px;
    height: auto;
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #6F257F;
    padding: 8px 0px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }