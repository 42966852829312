/* Loader Styles **/
#loader svg {
    fill: #0063c3;
    fill-opacity: 1 !important;
    stroke: #0063c3;
}
#loader svg circle{
    stroke-opacity: 1 !important;
}
#loader svg path{
    stroke: #eff2f5;
    fill-opacity: 1 !important;
}

.workflowBtn {
    background-color: #0063c3;
    color: #fff!important;
}