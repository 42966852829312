 /* error list search css starts */
.errorSearchForm .errorSearch, .errorSearchForm .clear {
    float: right;
    font-size: 14px;
    width: 104px;
    height: 32px;
    padding-top: 3px;
}
.errorSearchForm .errorSearch{
    margin-bottom: 10px;
    margin-left: 10px;
    /* margin-right: 9px;; */
}
.menu-content .errorSearchForm button.clear,
.menu-content .errorSearchForm button.clear:active,
.menu-content .errorSearchForm button.clear:hover,
.menu-content .errorSearchForm button.clear:focus,
.menu-content .errorSearchForm button.clear:visited,
.menu-content .errorSearchForm button.clear:active:focus{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    border: 1px solid #0063C3;
    color: #0063C3;
}
.menu-content .errorSearchForm button.errorSearch,
.menu-content .errorSearchForm button.errorSearch:active,
.menu-content .errorSearchForm button.errorSearch:hover,
.menu-content .errorSearchForm button.errorSearch:focus,
.menu-content .errorSearchForm button.errorSearch:visited,
.menu-content .errorSearchForm button.errorSearch:active:focus {
    box-shadow: none;
}
.cardBodyStyle{
    /* padding-top: 2px !important; */
    /* padding-bottom: 0px !important; */
   padding: 8px 16px !important;
}
.card .card-header {
	background: #023373;
    color: #ffffff;
    height: 40px;
    line-height: 24px;
    padding: 8px 16px !important;
    letter-spacing: 0;
}
.iconstyles-case {
	float: right;
    font-size: xx-large;
    width: 16px;
    height: 8px;
    line-height: 25px;
/*    margin-right: 10px;*/
    cursor: pointer;
}
/* .card .search-title {
    margin-left: 10px;
} */
.cardBodyStyle label {
	color:#414141;
    font-weight: bold;
    font-size: 13px;
}
.errorSearchForm input, .errorSearchForm select {
    font-size: 14px;
}
.errorSearchForm .form-group {
    max-width: 12.4%;
}
.errorSearchForm .form-control:focus {
    border-color: #0063C3;
    box-shadow: none;
}
/* error list search css ends */

/* error list css starts */

a.delete-error {
    color: #EA4335 !important;
    cursor: pointer;
    padding-left: 10px;
}
.error-details-row{
    padding: 1rem 2rem 0rem 2rem !important;
}
.padding-left-right{
    padding: 0rem 2rem ;
}
.btn-back{
    /* width: 17px;
    height: 8px; */
    position: relative;
    right: 0.5em;
}
.follow-up-back{
    width: 120px;
    height: 40px;
    font-size: 14px !important;
}
.lable-styles label{
font-weight: bold;
font-size: 14px;
}
.lable-styles .add-user-header .modal-title{
    font-size: 16px;
    font-weight: 600;
}
.edit-details-layout {
	border: 1px solid #ccc;
    padding: 9px;
	margin: auto;
    border-radius: 4px;
    background: #ffffff;
    font-size: 14px;
    color: #333333;
}
.edit-details-layout label {
	font-weight: bold;
    min-width: 33%;
    word-break: break-word;
}
.error-details-size{
    font-size: 20px;
}
.followupLink{
   color: #FFFFFF;
}
.followupLink:hover{
    text-decoration: none !important;
    color: #FFFFFF;
}
.clear .followupLink{
    color: #0063C3;
} 
.clear.btn.btn-primary:active{
    background-color: #FFFFFF !important;
}
.accordion>.card {
    overflow: visible !important;
}
.p-calendar .p-datepicker{
    margin-top: 5px;
}
 .p-button:enabled:hover{
    border-color: #CCCCCC !important;
 }
.p-inputtext{
    width: 80%;
}
.p-inputtext:hover{
    border-color: #CCCCCC !important;
}
.p-inputtext:focus{
box-shadow: none !important;
border-color: #CCCCCC !important;
}
.p-button {
    background-color: white !important;
    border: 1px solid #CCCCCC !important;
    border-left: none !important;
}
.p-button:focus{
    box-shadow: none !important;
}
.p-button-icon-left{
    color: gray;
}
.p-calendar.p-calendar-w-btn .p-inputtext{
    border: 1px solid #CCCCCC;
    border-right: none !important;
}
.a_followup{
position: relative;
top: 13px;
}
.a_followup a{
    padding: 10px 19px;
}
/* error list css ends */
.buttongroup
{
    display: inline-table;
    margin-right: 50% ;
}
.cmt {
    display: inline-block;
    padding: 6px;
    font-size: 11px;
    font-weight: 600;
}
.cmt span {
    display: inline-block;
}
.cmt .updatedBy {
    float:left;
}
.cmt .updatedAt {
    float: right;
}
.commentHistory {
    border-left: 3px solid #0063C3 !important;
}
.commentHistory .card-body {
    padding-top: 8px;
    padding-left: 6px;
}
.showCmt {
    font-size: 13px;
    font-weight: 600;
    color: #097eef !important;
    cursor: pointer;
}
.scrollcommentpopup{
    max-height: 360px;
    overflow-y: auto;
    width: 100%;
}

.layout-file{
    padding: 0px 1px;
}



  
