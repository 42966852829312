.tab-content .nav-tabs .nav-item.show .nav-link, .tab-content .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #0063C3;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: bold;
    border: 1px solid #0063C3;
}
.tab-content .nav-tabs .nav-link {
    background: #cccccc;
    color: #333333;
    font-weight: bold;
}

.tab-content .nav-tabs {
    border-bottom: 3px solid #0063C3;
}

.tab-content .nav-tabs .nav-item {
    margin-bottom: 0px;
    margin-right: 2px;
}
.chart-operations {
    position: absolute;
    right: 0;
    top: 80px;
    margin-right: 2%;
}
.chart-operations .dropdown {
    display: inline-block;
    padding-right: 12px;
}

.chart-operations button, .chart-operations button:hover, .chart-operations button:active {
    background: #fff;
    border: 1px solid #0063C3;
    color: #0063C3;
    font-size: 14px;
}
.chart-operations button span img {
    padding-right: 10px;
    width: 24px;
}
.btn-operation {
    display: inline-block;
}
.btn-operation button {
    margin-right: 12px;
}