.datamodel-charts .chart-outline {
    border: 1px solid #cccccc;
    background: #ffffff;
    min-height: 300px;
    margin-bottom: 18px;
    border-radius: 5px;
    padding-bottom: 15px;
}

.datamodel-charts .chart-pad-right {
    padding-right: 8px;
}

.datamodel-charts .chart-pad-left {
    padding-left: 8px;
}
.datamodel-charts {
    margin-top: 20px;
}