.notify{
    background-color: grey;
}
.or-circle h5 {
	border: 1px solid #99C3EC;
    width: 42px;
    height: 42px;
    padding: 12px;
    border-radius: 50%;
    font-size: 14px;
    color:#666666;
    background: #fff;
}
.file-row {
	border: 2px dashed #99C3EC;
    padding: -3px;
    height: 8em;
    position: relative;
    margin: auto;
    width: 100%;
    margin-left: 0px !important;
    border-radius: 4px;
    background: #E6F3FF;
}
.drag-text {
	margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #0063C3;
}
.plus-min{
     display: inline-block;
     margin-right: 5px;
     width:27%;
     vertical-align: bottom;
     /* margin-bottom: 5%; */
}
.plus-min .plus-min-box {
    width: 20px;
    height: 20px;
    border: 1px solid #0063C3; 
    vertical-align: middle;
    text-align: center;
    padding-bottom: 8px !important;
    cursor: pointer;
    background: #fff;
}
.plus-min .minus {
    border-radius: 4px 0px 0px 4px;
    padding: 6px 15px;
    /* border-right: 0; */
}
.plus-min .percent {
    border-radius: 0px;
    padding: 6px 6px 6px 6px; 
    /* padding: 6px 10px 7px 4px; */
    color: #414141;
}
.plus-min .plus {
    border-radius: 0px 4px 4px 0px;
    padding: 6px 14px;
    /* border-left: 0; */
}
span.plus-min-box.plus:hover, span.plus-min-box.minus:hover {
    background: #0063C3;
    color: #fff;
}
.downloadLink {
    text-decoration: none !important;
    color:#0063C3 !important;
    cursor: pointer;
   
}
.dataset-tableheader{
    padding-left:97px;
}
.sampling{
    text-decoration: none !important;
    color:#0063C3 !important;
    cursor: pointer;
    padding-right:21px;
}
.delete-error{
    padding-left:14px !important;
}
.dataset-action {
    width: 13px;
    height: 13px;
   /*  position: relative;
    top: -2px;
    right: 6px; */
    margin-right: 5px;
    margin-bottom: 3px;
}
.dataset-icon {

    margin-right: 10px;
}

.btn-extract {
    background: #0063C3;
    color: #fff;
    border: 1px solid #0063C3;
    padding: 4px 14px 9px 14px;
    border-radius: 5px;
    vertical-align: bottom;
    margin-bottom: 4%;
}
.dataset-title{
    font-size: 20px;
    text-transform: capitalize;
    color: #333;
}
.extract-btn-icon {
    width: 18px;
    height: 15px;
    margin-right: 10px
}
.extract-text {
    position: relative;
    top: 2px;
}
.datasetname-input {
    clear: both;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    opacity: 1;
    width: 300px;
    height: 40px;
}
.dataset-name-entry-content {
    width: 300px;
    margin-left: 33px;
}
.dataset-list-screen-dialog .modal-dialog {
    width: 400px !important;
}

.dataset-list-screen-dialog .modal-header {
    background-color: #0339A6;
    color:#fff;
}
.dataset-list-screen-dialog .modal-header .modal-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.dataset-list-screen-dialog .modal-header .close {
    color: #fff;
    opacity: 1;
}
.dataset-list-screen-dialog .modal-footer {
    border: 0;
}
.dataset-list-screen-dialog .modal-footer .btn {
    background-color: #0063C3;
    font-size: 14px !important;
}
/* .contentChange input::after{
    content:"%"
} */
.percentStyle{
    border: none;
    width: 15%;
    text-align: right;
}
.percentStyle:focus{
    outline: none;
}

.datasetStartdate{
    display: inline-grid;
    vertical-align: bottom;
    margin-bottom: 4%;
}

.datasetEnddate {
    display: inline-grid;
    vertical-align: bottom;
    margin-bottom: 4%;
    margin-left:15px;
    margin-right:25px;
}
/* .dataset-extract-dialog {

} */

.dataset-extract-dialog .modal-dialog {
    width: 400px !important;
}

.dataset-extract-dialog .modal-header {
    background-color: #ffffff;
    color:#023373;
    border-bottom: 0;
}
.dataset-extract-dialog .modal-header .modal-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.dataset-extract-dialog .modal-header .close {
    color: #fff;
    opacity: 1;
}
.dataset-extract-dialog .modal-footer {
    border: 0;
}
.dataset-extract-dialog .modal-footer .btn {
    background-color: #0063C3;
    font-size: 14px !important;
}
.progressMsg {
    font-size: 13px;
    color: #666666;
    margin-top: 6px;
    margin-bottom: -5px;
    font-style: italic;
}
.emptyAnchorTag{
    padding-left:107px;
}