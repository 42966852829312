.popupModal {
    max-width: 444px !important;
    text-align: center;
}
.confirm-popup-header {
    border-bottom: none !important;
    padding: 1rem 9rem !important;
    color: #023373;
}

.confirm-popup-header .close {
    padding: 1rem 1rem;
    right: 20px;
    position: absolute;
    top: 8px;
}

.confirm-popup-body {
    font-size: 14px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.confirm-popup-body .icon-img {
    text-align: center;
    width: 50px;
    height: 50px;
}
.confirm-popup-body .icon-img-error {
    text-align: center;
    /* width: 50px;
    height: 50px; */
}
.confirm-popup-body .success-styles {
    color: #0F9D58 !important;
    text-align: center;
    font: Regular 20px/20px Open Sans,sans-serif;
    font-size: 20px;
    padding-bottom: 10px;
}

.confirm-popup-footer {
    border-top: none !important;
    /* padding: 1rem 14rem !important; */
    margin: auto;
}
.confirm-popup-footer .btn-primary {
    padding: .375rem 2rem !important;
    font-size: 14px;
}