.form-search {
	margin-bottom: 10px;
}
.search-control {
    border-radius: 50px !important;
}
.search-icon {
	width: 16px;
    height: 16px;
    float: right;
    position: relative;
    bottom: 1.6rem;
    right: 0.7rem;
    cursor: pointer;
}