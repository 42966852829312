.iconstyles{
    float: right;
    font-size: xx-large;
}
.userSearch{
    margin-top: 30px;
    width: 94px;
}
.cardPadding{
    /* padding-bottom: 10px; */
    margin-bottom: 16px;
    /* border-bottom: 1px solid grey; */
    border: 1px solid #CCCCCC;
    border-radius: 5px;
}

.search-header{
    color: #FFFFFF;
    font-size: 16px;
    /* font-weight: 600; */
    background: #023373 !important;
}
.clear, .clear:active{
    background: #FFFFFF !important;
    color: #0063C3 !important;
    border: 1px solid #0063C3;
}
.icon-drop-down{
        transform: rotate(180deg);
        transition: transform 0s linear;     
        float: right;
        padding-bottom: 5px;
}
.icon-drop-up{
        transform: rotate(0deg);
        transition: transform 0s linear;
        float: right;
        padding-top: 5px;
}
.lable-names{
    font-size: 14px;
      font-weight: bold;
  }