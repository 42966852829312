.App {
  width: 1000px;
  margin: 0 auto;
  white-space: pre-line;
}

/* .logo {
  width: 254px;
  height: 39px;
  background: transparent url(../../assets/images/Logo-GIMS.svg) no-repeat;
  margin: auto;
} */

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}
