.App {
  width: 1000px;
  margin: 0 auto;
  white-space: pre-line;
}
.unauthorized-content {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100px;
}