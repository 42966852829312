.model-select-dropdown {
    float: right;
}
.selectModelVersion {
    width: 400px !important;
}
.selectModelVersion select {
    width: 100px;
}
.confirmation-footer-deploy {
    margin-top: 10px;
}
.cardBodyStyle-padding{
    padding:16px 16px !important;
}
