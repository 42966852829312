.multiSelheight .dropdown-heading {
    height: 35px !important;
    margin-top: 0px;
}
.multiSelheight .dropdown-heading-value {
    line-height: 32px !important;
}
.calender-set {
    width:100%;
}
.calender-set .p-datepicker-trigger.p-button {
    border-color: #0063C3 !important;
}
.calender-set .p-inputtext {
    border-color: #0063C3 !important;
}