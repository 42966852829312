svg {
    fill: #000;
    fill-opacity: 0.3;
  }
  
  path {
    stroke: #707070;
    stroke-width: 2px;
    fill: none;
  }
  
  .axis {
    stroke: #666666;
    font-size: 11px;
    font-weight: 400;
  }
  
  .axis-labels text {
    fill: #000 !important;
    fill-opacity: 0.9 !important;
    font-size: 12px;
    text-anchor: middle;
  }
  
  .axis-labels line {
    stroke: #000;
  }
  
  .gridline {
    opacity: 0.2;
  }
  
 .xy-labels {
   font-size: 12px !important;
   font-weight: bold;
   fill: #000 !important;
    fill-opacity: 1 !important;
 }
 .dotted {
   border: dotted 1px #CCCCCC;
 }
 div.tooltip {	
  position: absolute;			
  text-align: center;			
  width: 100px;					
  min-height: 25px;					
  padding: 5px;				
  font: 12px sans-serif;
  color: #fff;		
  background: #000;	
  border: 0px;		
  border-radius: 4px;			
  pointer-events: none;
  text-transform: uppercase;	
}