.samplingPopupModal {
    max-width: 840px !important;
    text-align: center;
    height:263px !important;
}
.samplingConfirm-popup-header {
    border-bottom: none !important;
    padding: 1rem 9rem !important;
    color: #023373;
}
.samplingDataset-confirm-popup-body {
   padding:16px !important
}
.samplingDataset-header{
    font-size: 16px;
    background: #0339A6;
    color: white;
    text-align: center;
    padding-left: 350px !important;
}
.samplingConfirm-popup-header .close {
    padding: 1rem 1rem;
    right: 20px;
    position: absolute;
    top: 8px;
    color:white !important;
}
.table-width{
    width:170px !important;
}
.table-teamwidth{
    width:150px !important;
}
.lastchild-style:last-child{
    font-weight: bold;
}