.upload-file {
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
.full-prescription-link-text {
  color: #000;
  font-size: 12px;
  float: left;
  line-height: 35px;
  text-decoration: underline;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-5 {
	margin-top: 5px;
}
.dropzone {
  position: relative;
  height: 5em; 
  margin-right: 12px;
  /* margin-top: 15%; */
}
.file-drop-icon {
  width: 32px;
  height: 27px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.select-file-title {
  font-size: 14px;
}