.searchForm .interactionSearch, .searchForm .clear {
    float: right;
    font-size: 14px;
    width: 104px;
    height: 32px;
    padding-top: 3px;
}
.searchForm .interactionSearch{
    margin-bottom: 10px;
    margin-left: 10px;
    /* margin-right: 9px; */
}
.menu-content .searchForm button.clear,
.menu-content .searchForm button.clear:active,
.menu-content .searchForm button.clear:hover,
.menu-content .searchForm button.clear:focus,
.menu-content .searchForm button.clear:visited,
.menu-content .searchForm button.clear:active:focus{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    border: 1px solid #0063C3;
    color: #0063C3;
}
.menu-content .searchForm button.interactionSearch,
.menu-content .searchForm button.interactionSearch:active,
.menu-content .searchForm button.interactionSearch:hover,
.menu-content .searchForm button.interactionSearch:focus,
.menu-content .searchForm button.interactionSearch:visited,
.menu-content .searchForm button.interactionSearch:active:focus {
    box-shadow: none;
}
.cardBodyStyle{
    /* padding-top: 2px !important; */
    /* padding-bottom: 0px !important; */
   padding: 8px 16px !important;
   cursor: pointer;
}
.card .card-header {
	background: #023373;
    color: #ffffff;
    height: 40px;
    line-height: 24px;
    padding: 8px 16px !important;
    letter-spacing: 0;
}
.iconstyles-case {
	float: right;
    font-size: xx-large;
    width: 16px;
    height: 8px;
    line-height: 25px;
/*    margin-right: 10px;*/
    cursor: pointer;
}

.cardBodyStyle label {
	color:#414141;
    font-weight: bold;
    font-size: 13px;
}
.searchForm input, .searchForm select {
    font-size: 14px;
}
.searchForm .form-group {
    max-width: 12.2%;
}
.searchForm .form-control:focus {
    border-color: #0063C3;
    box-shadow: none;
}
/* .interactionSearch-marginset{
    margin-top:-40px;
} */

.dropdown-content {
    width: 16em !important;    
}

.item-renderer span {
    display: inline !important;
}


