.menu-content {
    float: left;
    width: 90%;
    padding:10px;
    /* position: relative; */
    top: 5em;
    left: 5.5em;
    margin-top: calc(60px + 10px);
    margin-left: calc(100px + 10px);
    font-size: 14px;
    margin-bottom: 2rem;
}
.list-group-item{
    cursor: pointer;
    /* font-size: 14px; */
}
.view-details {
    color: #0063C3 !important;
    cursor: pointer;
}
.menu-content table th:last-of-type {
    text-align: center;
}
.download-subcases{
    margin-right: 11px;
    border: 1px solid #0063C3 !important;
    font-size: 14px !important;
}

.pno{
    padding-left: 15px !important;
}

.dropdown-menu{
    font-size: 14px !important;
    padding: 0 0 !important;
    min-width: auto;
    left: -32px !important;
    min-width: auto !important;
}

.dropdown-item{
    padding : .25rem .5rem !important;
}

