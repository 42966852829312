.width1{
    width: 13%;
}
/* .width1{
    width:7%;
    /* text-align: right; */
/* } 
.width2{
    width:9%;
    /* text-align: right; */
/* }
.width3{
    width:6%;
}  */
.width4{
    width: 13%;
    /* text-align: right; */
}
.popInteracationB{
    left: 0px !important;
    margin: 0px;
}

.popInteracationB .arrow{
    left: 72px !important;
}

.popInteracation{
    left: 90px !important;
    margin: 115px;
}

.popInteracation .arrow{
    left: 157px !important;
}
/* .countryTotalRow td{
    text-align: right;
} */

.countryLoading{
    height: 60px;
    width: 60px;
    margin: 6% 25%;
    position: relative;
}

.countryLoad{
    padding: 7% 38%;
    position: absolute;
}

.report-padding{
    margin-top: 20px;
}
.dataset-dropdown{
    width: 320px !important;
    height: 37px !important;
}
.datasetMargin{
    margin-left: 100px !important;
}
#DetailedReportDatsets .dropdown-content {
    width: 17em !important;
}
#datasetDropDnForEvlnRept{
    width: 73% !important;
}
.dnldBtnMargin{
    margin-left: 15px !important;
}