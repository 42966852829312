.scrollagentNotespopup{
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
}
.popupModalAgent {
    max-width: 444px !important;
    text-align: left;
}
.agentNotes-header{
    padding-left: 28rem !important;
    background: #0339A6;
    color: white;
    border: 1px solid #023373;
    font-weight: bold;
    margin-bottom: 10px !important;
}
.agentNotes-header .close{
    color:white !important;
}
.agentNotesPopup-iframe{
    width:100% !important;
    /* height: 400px; */
    border: 1px solid #ccc;
    border-radius: 2px;
    /* height: calc(100vh - 100px); */
    height: calc(100vh - 200px);
}

@media (min-width: 576px) {

.popupModalAgent {
    max-width: 1000px !important;
    margin: 1.75rem auto;
}
}   