.score-plus-minus{
    /* margin-top: 33px; */
    margin-top: 4px;
    width: 150% !important;
}
.alert-style{
    border: 1px dashed #0063C2 !important;
    border-radius: 10px !important;
    font-weight: bold;
    font-size: 14px;
    color: #333333 !important;
    background-color: #E5F2FF !important;
}
/* .right-align{
    float: right;
} */
.score-plus-minus .plus-min-box.plus{
    background: #0063C3;
    color: white;
    font-weight: bold;
}
.score-card{
    padding:1rem
}
.score-card label:after{
    content: none ;
}
.lable-width label{
/* width: 60%;
text-align: right; */
font-weight: bold;
    margin-bottom: 0px;
    min-width: 33%;
    word-break: break-word;
    width:50%;
    text-align: right;
    float: left;
}
.score-card span:before{
    content: ':' ;
    padding-right: 10px;
    padding-left: 10px;
}
.score-card-agent-notes{
    height: 70px;
    overflow-y: auto;
}
.alert-style img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.right-align{
    text-align: right;
}
.menu-content .download-font-size{
    font-size: 14px;
}
.row-end-border{
    border-top: 1px dashed #CCCCCC;
}
.score-details-layout {
	border: 1px solid #ccc;
    padding: 9px;
	margin: auto;
    border-radius: 4px;
    background: #ffffff;
    font-size: 14px;
    color: #333333;
}
.score-details-layout label {
	font-weight: bold;
    margin-bottom: 0px;
    word-break: break-word;  
    /* text-align: right; */
}
.score-details-layout span {
	color: #666666;
}
.score-details-layout .row{
	padding-bottom: 20px;
}
.edit-score-card {
    width: 180px;
    height: 40px;
    font-size: 14px !important;
}
 .follow-up-back {
    border: 1px solid #0063C3 !important;
}
.score-complete {
    margin-top: 20px !important;
}
.attach-include .form-check{
    display: inline-block;
    margin-left: 10px;
}
.score-card-total{
    /* width: 50%; */
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    margin-top: 15px;
}
.score-card-total .row{
    padding: 10px;
}
.score-card-total .row .col{
   text-align: right;
}
.aeid .AEID_Q2_AE_Missed, .aeid .AEID_Q2_AE_Missed_TCS, .aeid .AEID_Q2_AE_Missed_Amgen,
.aeid .AE_Cap_IRPC_Match, .aeid .AE_Cap_IRPC_Match_TCS, .aeid .AE_Cap_IRPC_Match_Amgen,
.aeid .AE_Q2_HCP, .aeid .AE_Q2_HCP_TCS, .aeid .AE_Q2_HCP_Amgen,
.aeid .AE_Q3_IPRC, .aeid .AE_Q3_IPRC_TCS, .aeid .AE_Q3_IPRC_Amgen,
.aeid .PC_Q1_IPRC_Match ,.aeid .PC_Q1_IPRC_Match_TCS, .aeid .PC_Q1_IPRC_Match_Amgen,
.aeid .PCID_Q2_Missed, .aeid .PCID_Q2_Missed_TCS, .aeid .PCID_Q2_Missed_Amgen{
    border-color: red;
}
/* .scoreError{
    border-color: red !important;
} */
.original-qm-width{
    width: 10%;
}
.qc-error-width{
    width:10%;
}
.editScoreForm{
margin-bottom: 15px;
}
.cardPadding .searchForm .createSample {
    width: 156px;
}
.performSampling>.card {
    overflow: visible !important;
}
.modalHeader {
    padding-left: 11.5rem !important;
    background: #0339A6;
    color: white;
    border: 1px solid #023373;
    font-weight: bold;
    font-size: 16px !important;
}
.modalHeader .h4 {
    font-size: 16px !important;    
}
.modal-body, .modal-footer, .modal-body .form-control, .modal-footer button {
    font-size: 14px !important;
}
.modal-footer button {
    width: 120px;
}
.modalHeader .close{
    font-weight: normal !important;
    color: #FFFFFF !important;
    opacity: 1;
}
.searchScorecard label, .performSampling label {
    white-space: nowrap;
}

/* .performSampling .dropdown-heading .dropdown-heading-value span {
    color: rgb(51, 51, 51) !important;
} */

.multi-select .dropdown-content .select-panel .select-item {
    margin-bottom: 0;
}

.multi-select .dropdown-content .select-panel .select-item .item-renderer{
    font-weight: normal !important;
}

.multi-select .dropdown-content .select-panel .select-item .item-renderer input[type="checkbox"] {
    width: 16px;
    height: 16px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #333333 !important; */
}

.select-item .item-renderer span {
    vertical-align: text-bottom !important;
}

.scorecard-details .follow-up-back {
    margin-bottom: 26px;
}

.attachment-included{

    display: table-cell !important;
    padding-right: 10px;

}

.cmt {
    display: inline-block;
    padding: 6px;
    font-size: 11px;
    font-weight: 600;
}
.cmt span {
    display: inline-block;
}
.cmt .updatedBy {
    float:left;
}
.cmt .updatedAt {
    float: right;
}
.commentHistory {
    border-left: 3px solid #0063C3 !important;
}
.commentHistory .card-body {
    padding-top: 8px;
    padding-left: 6px;
}
.showCmts {
    font-size: 13px;
    font-weight: 600;
    color: #097eef !important;
    cursor: pointer;
    float: right ;
}
.scrollcommentpopup{
    max-height: 360px;
    overflow-y: auto;
    width: 100%;
}

.searchForm .scorecardSearch{
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 9px;
}

.menu-content .searchForm button.scorecardSearch,
.menu-content .searchForm button.scorecardSearch:active,
.menu-content .searchForm button.scorecardSearch:hover,
.menu-content .searchForm button.scorecardSearch:focus,
.menu-content .searchForm button.scorecardSearch:visited,
.menu-content .searchForm button.scorecardSearch:active:focus {
    box-shadow: none;
}

.searchForm .scorecardSearch, .searchForm .clear {
    float: right;
    font-size: 14px;
    width: 104px;
    height: 32px;
    padding-top: 3px;
}
.scorecard_percentStyle{
    border: none;
    width: 15%;
    text-align: right;
}
.scorecard_percentStyle:focus{
    outline: none;
}
.country-item {
    display: flex;
    justify-content: space-between;
  }
  .delOption {
    cursor: pointer;
    color: red;
  }
  .p-dropdown {
    min-width: 8rem !important;
  }

  body .p-dropdown .p-dropdown-label {
    padding-right: 0em !important;
  }