@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans',sans-serif !important;
  color: #333333 !important;
  background: #F2F2F2 !important;
}
.hidden {
  display: none !important;
}
#loader {
	display: none;
}
#loader > div {
  z-index: 1060;
}
#loader .loader-container {
  z-index: 1058;
  position: fixed;
  opacity: .6;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}
#nprogress .bar, #nprogress .spinner{
  z-index: 1061 !important;
}
table {
	border: 1px solid #CCCCCC !important;
	border-radius: 5px 5px 0px 0px !important;
	/*text-align: center;*/
}

table th {
	cursor: pointer;
}
.header-color{
  background: #023373;
}
.button-color{
  background: #0063C3 !important;
	/* border-bottom: 1px solid #cccccc; */
	border-radius: 0.3125rem !important;
}

table th i {
	padding-left: 10px;
}

table td {
	/*text-align: center;*/
	background: #fff;
    border-bottom: 1px solid #ccc;
}
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle{
	background-color: #0063C3 !important;
}
.btn:focus, .btn-primary:focus{
  box-shadow: none !important;
}
.arrow-left-right-end{
  width: 10px;
    height: 10px;
}
.arrow-left-right{
  width: 5px;
  height: 10px;
}
.success-styles{
  color: #0F9D58 !important;
  text-align: center;
  font: Regular 20px/20px Open Sans,sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}
.error-styles{
  color: #EA4335 !important;
  text-align: center;
  font: Regular 20px/20px Open Sans, sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}
.success-img{
  text-align: center;
  width: 50px;
  height: 50px;
}
 /* @media only screen and (max-height: 700px) {

    
}
@media only screen and (min-height: 701px) {
  
 
}  */

@media only screen and (max-width: 1297px)
{
.plus-min{
  margin-bottom: 3% !important;
}
.datasetStartdate{
  margin-bottom: 2% !important;
}
.datasetEnddate{
  margin-bottom: 2% !important;
}
.btn-extract{
  margin-bottom: 1.5% !important;
}
}

@media only screen and (min-width: 1298px)
{
  .plus-min{
    margin-bottom: 5% !important;
  }
  .datasetStartdate{
    margin-bottom: 4% !important;
  }
  .datasetEnddate{
    margin-bottom: 4% !important;
  }
  .btn-extract{
    margin-bottom: 4% !important;
  }
}

.float-left {
  float: left;
}

.margin-left-10 {
  margin-left: 10px;
}
.description{
  width: 100%;
}
.details-layout .details-row label::after{
content: ':';
padding-left: 8px;
padding-right: 0px;
}

.details-layout .details-row span{
  /* width: 60%; */
  float: left;
  padding-left: 8px;
}
.details-layout .details-row .col-md-4{
padding-left: 0px !important;
padding-right: 0px !important;
}
.opacityBlack {
  fill-opacity: 1;
}
.list-group-item.active {
  z-index: 0 !important;
  color: #fff;
}
.p-growl {
  position: fixed;
  width: 25em !important;
}